import React, { useEffect, createRef } from "react"
import lottie from "lottie-web"
// import {
//   graphql,
//   useStaticQuery,
// } from "gatsby"

// Media Imports
import design from '../animations/design.json'
import resume from '../files/chance-murphy-resume.pdf'

// Component Imports
import Layout from "../layout"

const Home = () => {
  // const data = useStaticQuery(
  //   graphql`
  //   query {
  //   }
  // `)

  let animationContainer = createRef();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: design
    });
    return () => anim.destroy(); // optional clean up for unmounting
  }, []); // eslint-disable-line

  return (
    <Layout>


      <section class="home-banner">
        <div class="sw">
          <div class="home-text">
            <h1>I'm Chance, a UX Designer and Frontend React Developer based out of Ann Arbor, Michigan.</h1>
          </div>
        </div>
      </section>


      <section class="landing-content">
        <div class="sw">
          <div class="content">
            <div class="left">
              <p>I take an agile, data-driven approach to my work, iterating and improving digital products to create holistic, user-friendly interfaces and applications. I enjoy the challenge of setting goals and designing solutions that work for everyone. This site is currently evolving, and there will be more updates coming soon.</p>
              <div class="skills">
                <div class="design">
                  <h2>Design</h2>
                  <ul>
                    <li>UX Design</li>
                    <li>UX Research</li>
                    <li>Branding</li>
                    <li>Graphic Design</li>
                    <li>Prototyping</li>
                  </ul>
                </div>
                <div class="dev">
                  <h2>Development</h2>
                  <ul>
                    <li>React</li>
                    <li>React Native</li>
                    <li>Gatsby.js</li>
                    <li>HTML/CSS</li>
                    <li>SCSS</li>
                    <li>Python</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="animation-container" ref={animationContainer} />
            </div>
          </div>
        </div>
      </section>


      <section class="resume-cta">
        <div class="sw">
          <div class="content">
            <h3>Interested in working together?</h3>
            <a href={resume} target="_blank" rel="noreferrer" class="cta">View Resume</a>
          </div>
        </div>
      </section>


    </Layout>
  )
}

export default Home
